import React, { HTMLInputTypeAttribute } from "react";
import { Toaster } from "react-hot-toast";

export function Button(props: {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => any;
  smaller?: boolean;
  textClass?: string;
  fullWidth?: boolean;
}) {
  return (
    <button
      className={`${
        props.smaller ? "w-28" : "w-40"
      } h-12 p-4 bg-violet-300 rounded border-2 border-blue-200 flex items-center justify-center font-normal ${
        props.textClass
      } ${props.fullWidth ? "w-full" : ""}`}
      onClick={(e) => props.onClick(e)}
    >
      {props.children}
    </button>
  );
}

export function Header(props: { right?: React.ReactNode }) {
  return (
    <div className="flex flex-row py-8 w-full px-2 sm:px-0 items-end justify-between text-slate-600">
      <h1 className="text-3xl">skeetbowl! 🎄</h1>
      {props.right}
    </div>
  );
}

export function Input({
  value,
  placeholder,
  setValue,
  type,
}: {
  value: string;
  placeholder: string;
  setValue: (value: string) => void;
  type?: HTMLInputTypeAttribute;
}) {
  return (
    <input
      className="h-10 p-3 w-full text-2xl rounded border-4 border-blue-200 font-light bg-inherit"
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    ></input>
  );
}

export function Section({ children }: { children: React.ReactNode }) {
  return (
    <div className="relative w-full p-4 rounded border-1 bg-[#E6FDFF] flex-col items-center inline-flex">
      {children}
    </div>
  );
}

export function Page({ children }: { children: React.ReactNode }) {
  return (
    <div
      id="page"
      className="flex flex-col items-center font-light text-slate-700"
    >
      <div className="w-full max-w-lg">{children}</div>
      <Toaster />
    </div>
  );
}
