import React from "react";
import { Meeting, pricingEligibleBalancesPOST, PaymentMethods } from "./api";
import { useMutation, useQueryClient } from "react-query";
import { dayToString, getOptimalPaymentMethods } from "./lib";
import { bookingModalInfoAtom } from "./BookingModal";
import { useSetAtom } from "jotai";
import { findSession, inferSessionDate } from "./QuickBook";
import { Button } from "./components";

/**
 * States:
 * - Idle/Default: Shows timeslot
 * - Finding session: Confirming that the session corresponding to the timeslot
 *   exists
 * - Session not found / error
 * - Confirm booking: Session is found, click to confirm booking. After this,
 *   the button disappears and a loading gif is shown in place of QuickBook
 */
export function BookButton(props: {
  time: string;
  date?: Date;
  disabled: boolean;
  book: (args: {
    session: Meeting;
    timeString: string;
    paymentMethods: PaymentMethods;
  }) => void;
  smaller?: boolean;
}) {
  const queryClient = useQueryClient();
  const findSessionMutation = useMutation({
    mutationFn: async (session: Date) => {
      const [eligibleBalances, foundSession] = await Promise.all([
        queryClient.fetchQuery("pricingEligibleBalances", {
          queryFn: async () => pricingEligibleBalancesPOST(31),
          staleTime: 0,
        }),
        findSession(
          session,
          queryClient.fetchQuery<string[]>("allBookedMeetings", {
            staleTime: 15 * 1000,
          })
        ),
      ]);
      const optimalPaymentMethods = getOptimalPaymentMethods(
        foundSession.cost,
        eligibleBalances
      );
      return {
        optimalPaymentMethods,
        session: foundSession,
      };
    },
  });
  const setBookingModalInfo = useSetAtom(bookingModalInfoAtom);

  const time = props.time;
  const [buttonStr, textClass] = React.useMemo(() => {
    const status = findSessionMutation.status;
    const textSize = props.smaller ? "text-base" : "text-lg";
    if (status === "loading") {
      return ["loading...", textSize];
    } else if (status === "success") {
      const meeting = findSessionMutation.data.session;
      const dayStr = dayToString[meeting.date.getDay()];
      const cost = meeting.cost;
      const str = props.smaller
        ? `book? ($${cost})`
        : `book ${dayStr}? ($${cost})`;
      return [str, textSize];
    } else if (status === "error") {
      const error = findSessionMutation.error as Error;
      if (error.message.includes("Already booked")) {
        return ["already booked!", textSize];
      } else {
        return ["not found :(", textSize];
      }
    } else {
      return [time, "text-2xl"];
    }
  }, [findSessionMutation, time, props.smaller]);

  return (
    <Button
      onClick={async (e) => {
        e.stopPropagation();
        if (findSessionMutation.isIdle) {
          // idle state; find the session first
          const { optimalPaymentMethods, session } =
            await findSessionMutation.mutateAsync(
              props.date ? props.date : inferSessionDate(time)
            );
          // If paying with non-zero credits, interrupt and show booking modal
          if (optimalPaymentMethods.some((p) => p.type !== "creditCard")) {
            setBookingModalInfo({
              session,
              paymentMethods: optimalPaymentMethods,
              timeString: time,
            });
            findSessionMutation.reset();
          }
          setTimeout(() => {
            // reset button state
            findSessionMutation.reset();
          }, 6000);
        } else if (findSessionMutation.isSuccess) {
          props.book({
            timeString: time,
            session: findSessionMutation.data.session,
            paymentMethods: findSessionMutation.data.optimalPaymentMethods,
          });
          findSessionMutation.reset();
        } else {
          findSessionMutation.reset();
        }
      }}
      textClass={textClass}
      smaller={props.smaller}
    >
      {buttonStr}
    </Button>
  );
}
