import { useQuery } from "react-query";
import {
  multiClassTimeSlotsPOST,
  useAllBookedMeetings,
  useBookMeetingMutation,
} from "./api";
import { groupBy } from "lodash";
import { Section } from "./components";
import { dayToString, dateToShortTime } from "./lib";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingDots } from "./LoadingDots";
import { BookButton } from "./BookButton";
import { PaymentMethodsInfo } from "./QuickBook";
import penguin from "./penguin_money.gif";
import { atom, useAtom } from "jotai";
import { useState } from "react";

function getDurationMins(end: string, start: string) {
  const [endH, endM] = end.split(":").map(Number);
  const [startH, startM] = start.split(":").map(Number);
  const diffMs =
    new Date().setHours(endH, endM) - new Date().setHours(startH, startM);
  return Math.round(diffMs / 1000 / 60);
}

type TimeSlot = {
  meetingKey: string;
  durationMins: number;
  dateOnly: Date;
  dateTime: Date;
  currentEnrollment: number;
  reserved: boolean;
};
export const scheduleExpandedDateAtom = atom<string | null>(null);
export function Schedule() {
  const { data: bookedData, isFetching: bookedIsFetching } =
    useAllBookedMeetings();
  const { status, data, isFetching } = useQuery("multiTimeSlot", async () => {
    const result: TimeSlot[] = [];
    const weekSlots = await Promise.all([
      multiClassTimeSlotsPOST(1),
      multiClassTimeSlotsPOST(2),
      multiClassTimeSlotsPOST(3),
      multiClassTimeSlotsPOST(4),
      multiClassTimeSlotsPOST(5),
      multiClassTimeSlotsPOST(6),
      // Not sure which one it is
      multiClassTimeSlotsPOST(7),
      multiClassTimeSlotsPOST(0),
    ]);
    weekSlots.forEach((slots) => {
      slots.forEach((slot) => {
        if (slot.locationName !== "Freestyle") {
          return;
        }
        slot.meetings.forEach((meeting) => {
          result.push({
            meetingKey: meeting.meeting.objKey,
            reserved: meeting.reserved,
            durationMins: getDurationMins(slot.endTime, slot.startTime),
            dateOnly: new Date(
              // force into PST
              meeting.meeting.date + " 00:00:00"
            ),
            dateTime: new Date(meeting.meeting.date + " " + slot.startTime),
            currentEnrollment: meeting.currentEnrollment,
          });
        });
      });
    });

    // sort by date
    return result.sort((a, b) => a.dateTime.getTime() - b.dateTime.getTime());
  });
  const [expandedDateStr, setExpandedDate] = useAtom(scheduleExpandedDateAtom);
  const TO_SHOW = 6;
  const [showNum, setShowNum] = useState(TO_SHOW);
  const slots = data || [];
  const slotsByDay = groupBy(slots, (slot) => slot.dateOnly.toDateString());
  const expandedDate: Date | undefined =
    slotsByDay[expandedDateStr || ""]?.[0]?.dateOnly;
  const headerString = expandedDate
    ? `← booking ${
        dayToString[expandedDate.getDay()]
      } ${expandedDate.getDate()}`
    : "upcoming crowdedness";

  return (
    <Section>
      <h2
        className="text-slate-600 w-full text-lg mb-4"
        onClick={() => setExpandedDate(null)}
      >
        {headerString}
        {(isFetching || bookedIsFetching) && <LoadingDots />}
      </h2>
      {status !== "success" ? (
        <div>loading...</div>
      ) : expandedDateStr ? (
        <DaySlotSingle
          slots={slotsByDay[expandedDateStr]}
          bookedMeetingKeys={bookedData}
        />
      ) : (
        <div>
          <div className="w-full grid grid-cols-2 gap-4 max-w-sm">
            {Object.keys(slotsByDay)
              .slice(0, showNum)
              .map((date) => {
                return (
                  <DaySlots
                    bookedMeetingKeys={bookedData}
                    key={date}
                    slots={slotsByDay[date]}
                    onClick={() => setExpandedDate(date)}
                  />
                );
              })}
          </div>
          {showNum < Object.keys(slotsByDay).length && (
            <div className="flex flex-row justify-center">
              <button
                className="mt-5 text-slate-500 text-sm underline decoration-dotted"
                onClick={() => setShowNum((n) => n + TO_SHOW)}
              >
                show more
              </button>
            </div>
          )}
        </div>
      )}
    </Section>
  );
}

function DaySlotSingle(props: {
  slots: TimeSlot[];
  bookedMeetingKeys: string[] | undefined;
}) {
  const slots = props.slots;
  const bookMutation = useBookMeetingMutation();

  return (
    <div className="bg-amber-100 p-6 w-full">
      {bookMutation.isLoading ? (
        <div className="flex flex-col items-center justify-center h-24">
          <img
            className="w-20 h-20 opacity-80 rounded"
            src={penguin}
            alt="penguin money"
          />
        </div>
      ) : bookMutation.isSuccess ? (
        <div className="flex flex-col items-center justify-center h-24 text-lg">
          <div>successfully booked! 🎉</div>
          <PaymentMethodsInfo paymentMethods={bookMutation.data} />
        </div>
      ) : (
        <div
          className="text-xl justify-center items-center"
          style={{
            display: "grid",
            gridTemplateColumns: "4fr 2fr 2fr",
            rowGap: "20px",
            columnGap: "4px",
          }}
        >
          {slots.map((slot) => (
            <div className="contents" key={slot.dateTime.toString()}>
              <span className="col-start-1 text-2xl">
                {props.bookedMeetingKeys?.includes(slot.meetingKey) && (
                  <div className="absolute -ml-[20px] -mt-[-6px]">
                    <CheckIcon color="success" fontSize="small" />
                  </div>
                )}
                <BookButton
                  key={dateToShortTime(slot.dateTime)}
                  time={dateToShortTime(slot.dateTime)}
                  date={slot.dateTime}
                  disabled={bookMutation.isLoading}
                  book={async (args) => {
                    await bookMutation.mutateAsync(args);
                  }}
                  smaller
                />
              </span>
              <span className={"col-start-2"}>{slot.durationMins}min</span>
              <span className={"col-start-3 justify-self-end"}>
                {slot.currentEnrollment}⛸
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function DaySlots(props: {
  slots: TimeSlot[];
  bookedMeetingKeys: string[] | undefined;
  onClick: () => void;
}) {
  const date = props.slots[0].dateOnly;
  const dateStr = `${dayToString[date.getDay()]} ${date.getDate()}`;
  const bookedMeetingKeys = props.bookedMeetingKeys || [];
  return (
    <div className="bg-amber-100 p-5" onClick={props.onClick}>
      <div className="text-xl font-normal">{dateStr}</div>
      {props.slots.map((slot) => (
        <div
          key={slot.dateTime.toString()}
          className="text-lg text-justify flex flex-row"
        >
          <span className="flex-auto flex flex-row items-center mr-4">
            {bookedMeetingKeys.includes(slot.meetingKey) && (
              <div className="absolute -ml-[18px] -mt-[3px] text-l">
                <CheckIcon color="success" fontSize="inherit" />
              </div>
            )}
            {dateToShortTime(slot.dateTime)}
            {/* Indicate anything >60mins */}
            {slot.durationMins > 60 && "*"}
          </span>
          <span className="flex-auto flex flex-row-reverse">
            {slot.currentEnrollment}⛸
          </span>
        </div>
      ))}
    </div>
  );
}
